import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import colors from 'vuetify/lib/util/colors';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.blue.lighten3,
        secondary: colors.shades.white,
        cardcolor: colors.shades.white,
        cardcolorbottom: colors.grey.darken2,
        profilecardcolor: colors.shades.white,
        infocardcolor: colors.shades.white,
        titlecardcolor: colors.shades.white,
        editmode: colors.orange.lighten5,
        chipcolor: colors.orange.lighten3,
        accent: colors.orange.base,
        error: colors.red.lighten2,
        warning: colors.orange.base,
        success: colors.green.lighten1,
        titlebar: colors.shades.black
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
});
