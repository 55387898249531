














































import { Component, Vue } from 'vue-property-decorator';
import { IAdvertiser } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile';

@Component({
  components: {}
})
export default class Advertisers extends Vue {
  public advertisers: IAdvertiser[] = [];

  private async created(): Promise<void> {
    await this.getAdvertisers();
    setInterval(async () => {
      await this.getAdvertisers();
    }, 20000);
  }

  private async clicked(searchId: string): Promise<void> {
    await ApiProfile.visit({ SearchId: searchId });
  }

  // Get advertisers
  private async getAdvertisers(): Promise<void> {
    this.advertisers = await ApiProfile.advertisers();
  }

  private randomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
