import axios, { AxiosError, AxiosRequestConfig, AxiosInstance } from 'axios';

// Get basic configuration once
const getConfig = axios.get(`${process.env.BASE_URL}config.${process.env.NODE_ENV}.json`);

// Create an axios instance configured with relevant baseUrl
// and add intecepter with JWT token
export const getApi = async (base: string): Promise<AxiosInstance> => {
  const config = await getConfig;
  const instance = axios.create({
    baseURL: config.data.apiUrl + base
  });
  instance.interceptors.request.use((cfg: AxiosRequestConfig) => {
    const token = window.sessionStorage.getItem('token');
    cfg.headers.Authorization = `Bearer ${token}`;
    return cfg;
  });
  return instance;
};

// Get error message from Axios call
// As standard, the response data object should contains and error object with a message element
export function getErrorMessage(error: AxiosError): string {
  if (error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
    return 'Ikke autoriseret';
  } else if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined &&
    error.response.data.error.message !== undefined
  ) {
    return String(error.response.data.error.message);
  } else if (error.response !== undefined && error.response.data !== undefined) {
    return String(error.response.data);
  } else if (error.response !== undefined && error.message !== undefined) {
    return error.message;
  } else if (error.request !== undefined) {
    return 'Intet svar fra back end';
  } else {
    return error.message;
  }
}
