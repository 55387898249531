import {
  IAdvertiser,
  IActivationData,
  IActivationResult,
  IAuthenticationResult,
  ICreationData,
  ICreationResult,
  IExistsResult,
  ISearchResult,
  IProfileIdResult,
  IProfileCardResult,
  IProfileResult,
  IProfileUpdateResult,
  IProfileUpdateData,
  IProfileVisitData,
  IProfileVisitResult,
  IProfileCompetenciesUpdateResult,
  IProfileCompetenciesUpdateData,
  IProduct,
  IVisitResults,
  IAuthenticationData,
  IPasswordResetData,
  IPasswordResetResult,
  IPasswordRecoverData,
  IPasswordRecoverResult
} from '@/scripts/models';
import { getApi } from '@/scripts/apiHelper.ts';

export class ApiProfile {
  public static apiBase = 'profile';

  public static async activate(payload: IActivationData): Promise<IActivationResult> {
    const response = await (await getApi(this.apiBase)).put('activate', payload);
    return response.data as IActivationResult;
  }

  public static async authenticate(payload: IAuthenticationData): Promise<IAuthenticationResult> {
    const response = await (await getApi(this.apiBase)).post(`authenticate`, payload);
    return response.data as IAuthenticationResult;
  }

  public static async profileIdByUserName(userName: string): Promise<IProfileIdResult> {
    const response = await (await getApi(this.apiBase)).get(`profileid/${encodeURIComponent(userName)}`);
    return response.data as IProfileIdResult;
  }

  public static async profileByProfileId(profileId: string): Promise<IProfileResult> {
    const response = await (await getApi(this.apiBase)).get(`${encodeURIComponent(profileId)}`);
    return response.data as IProfileResult;
  }

  public static async profileCardData(searchId: string): Promise<IProfileCardResult> {
    const response = await (await getApi(this.apiBase)).get(`carddata?searchid=${encodeURIComponent(searchId)}`);
    return response.data as IProfileCardResult;
  }

  public static async create(payload: ICreationData): Promise<ICreationResult> {
    const response = await (await getApi(this.apiBase)).post('', payload);
    return response.data as ICreationResult;
  }

  public static async exists(userName: string): Promise<IExistsResult> {
    const response = await (await getApi(this.apiBase)).get(`exists?username=${encodeURIComponent(userName)}`);
    return response.data as IExistsResult;
  }

  public static async update(payload: IProfileUpdateData): Promise<IProfileUpdateResult> {
    const response = await (await getApi(this.apiBase)).put('', payload);
    return response.data as IProfileUpdateResult;
  }

  public static async updateCompetencies(
    payload: IProfileCompetenciesUpdateData
  ): Promise<IProfileCompetenciesUpdateResult> {
    const response = await (await getApi(this.apiBase)).put('competencies', payload);
    return response.data as IProfileCompetenciesUpdateResult;
  }

  public static async visit(payload: IProfileVisitData): Promise<IProfileVisitResult> {
    const response = await (await getApi(this.apiBase)).post('visits', payload);
    return response.data as IProfileVisitResult;
  }

  public static async sendRecoverPasswordMail(payload: IPasswordRecoverData): Promise<IPasswordRecoverResult> {
    const response = await (await getApi(this.apiBase)).post('recoverpassword', payload);
    return response.data as IPasswordRecoverResult;
  }

  public static async resetPassword(payload: IPasswordResetData): Promise<IPasswordResetResult> {
    const response = await (await getApi(this.apiBase)).put('resetpassword', payload);
    return response.data as IPasswordResetResult;
  }

  public static async visits(profileId: string, date: string): Promise<IVisitResults[]> {
    const response = await (await getApi(this.apiBase)).get(
      `visits?profileid=${encodeURIComponent(profileId)}&date=${encodeURIComponent(date)}`
    );
    return response.data as IVisitResults[];
  }

  public static async profileProduct(profileId: string): Promise<IProduct> {
    const response = await (await getApi(this.apiBase)).get(`products?profileid=${encodeURIComponent(profileId)}`);
    return response.data as IProduct;
  }

  public static async profilesFiltered(
    categoryId: number,
    competencyId: number,
    regionId: number,
    cityId: number
  ): Promise<ISearchResult[]> {
    const url = `search?categoryid=${categoryId}&competencyid=${competencyId}&regionid=${regionId}&cityid=${cityId}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ISearchResult[];
  }

  public static async advertisers(): Promise<IAdvertiser[]> {
    const response = await (await getApi(this.apiBase)).get('advertisers');
    return response.data as IAdvertiser[];
  }
}
