
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMenuItem } from '@/scripts/models';

@Component({
  components: {}
})
export default class Navbar extends Vue {
  @Prop({ required: true })
  private menuItems!: IMenuItem[];

  private drawer = false;
}
