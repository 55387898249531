//
// A collection of helper functions and classes
//

import {
  IRegion,
  ICity,
  ICategory,
  ICompetency,
  IProduct,
  ICouponCode,
  IPageContent,
  IProfileCompetenciesResult,
  ICompetenciesForProfileResult
} from '@/scripts/models';
import { getApi } from '@/scripts/apiHelper.ts';

// API calls
export class ApiCommon {
  public static apiBase = 'common';
  // Common controller calls

  // Get regions
  public static async getRegions(): Promise<IRegion[]> {
    const url = 'regions';
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IRegion[];
  }

  // Get cities for region
  public static async getCities(regionId: number): Promise<ICity[]> {
    const url = 'cities?regionid=' + regionId;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ICity[];
  }

  // Get all cities
  public static async getAllCities(): Promise<ICity[]> {
    const url = 'allcities';
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ICity[];
  }

  // Get categories
  public static async getCategories(): Promise<ICategory[]> {
    const url = 'categories';
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ICategory[];
  }

  // Get competencies for catagory
  public static async getCompetencies(categoryId: number): Promise<ICompetency[]> {
    const url = 'competencies?categoryid=' + categoryId;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ICompetency[];
  }

  // Get competencies for profile/Searchid
  public static async getCompetenciesBySearchId(searchId: string): Promise<IProfileCompetenciesResult[]> {
    const url = 'competenciesbysearchid?searchid=' + searchId;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IProfileCompetenciesResult[];
  }

  // Get competencies for profile/profileId
  public static async getCompetenciesByProfileId(profileId: string): Promise<ICompetenciesForProfileResult[]> {
    const url = 'competenciesbyprofileid?profileid=' + profileId;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ICompetenciesForProfileResult[];
  }

  // Get products
  public static async getProducts(): Promise<IProduct[]> {
    const url = 'products';
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IProduct[];
  }

  // Get product
  public static async getProduct(id: number): Promise<IProduct> {
    const url = `products/${id}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IProduct;
  }

  // Get coupon code
  public static async getCouponCode(couponName: string, productId: number): Promise<ICouponCode> {
    const url = `couponcode?couponname=${encodeURIComponent(couponName)}&productid=${productId}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ICouponCode;
  }

  // Get terms
  public static async getTerms(): Promise<IPageContent> {
    const url = 'terms';
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IPageContent;
  }
}
