





























import { Component, Vue } from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue';
import Advertisers from '@/components/Advertisers.vue';
import Terms from '@/components/Terms.vue';
import { IMenuItem } from './scripts/models';
import * as sec from '@/scripts/security.ts';

@Component({ components: { Navbar, Advertisers, Terms } })
export default class App extends Vue {
  private backg = '';

  private staticMenuItems: IMenuItem[] = [
    { title: 'Om', icon: 'mdi-information-variant', link: '/about' },
    {
      title: 'Produkter',
      icon: 'mdi-cart',
      link: '/productpage'
    }
  ];

  private menuItems = {} as IMenuItem[];

  // get random background
  private mounted(): void {
    this.backg =
      'width: 100%;height: 100%;position: absolute; opacity: 0.6;background-position: center center;background-size: cover;background-repeat: repeat;backface-visibility: hidden;transform: scale(1);' +
      "background-image: url('/static/Back" +
      Math.floor(Math.random() * 6 + 1) +
      ".jpg')";
    this.updateMenu(sec.isJwtValid());
  }

  // Update menu according to logged in status
  private updateMenu(loggedIn: boolean): void {
    this.menuItems = [
      ...this.staticMenuItems,
      loggedIn
        ? { title: 'Log ud', icon: 'mdi-account', link: '/logout' }
        : { title: 'Log ind', icon: 'mdi-account', link: '/dashboard' }
    ];
  }
}
