


























import { Component, Vue } from 'vue-property-decorator';
import { ApiCommon } from '@/scripts/apiCommon';
import * as lib from '@/scripts/apiHelper.ts';

@Component({ components: {} })
export default class Terms extends Vue {
  private showTermsDialog = false;
  private terms = '';
  private error = '';

  private async mounted(): Promise<void> {
    try {
      this.error = '';
      const result = await ApiCommon.getTerms();
      if (result) {
        this.terms = result.Content;
      }
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    }
  }
}
