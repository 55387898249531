/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// This routers returned content is shown in the App.vue between the MainMenu and the Advertisers components
export default new Router({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
      path: '/productpage',
      name: 'product page',
      component: () => import(/* webpackChunkName: "productpage" */ '@/views/ProductPage.vue')
    },
    {
      path: '/activate',
      name: 'activation',
      component: () => import(/* webpackChunkName: "activate" */ '@/views/Activate.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
    },
    {
      path: '/recoverpassword',
      name: 'recoverpassword',
      component: () => import(/* webpackChunkName: "recoverpassword" */ '@/views/RecoverPassword.vue')
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: () => import(/* webpackChunkName: "resetpassword" */ '@/views/ResetPassword.vue')
    }
  ]
});
